<template>
    <div class="d-flex justify-space-between tabs-container">
        <button v-for="content in faqArray" :key="content.id" @click="changeTab($t(content.title), content.content)"
            class="p-4 rounded-xl d-flex align-center button-tab"
            :class="activeTitle === $t(content.title) ? 'faq-active-tab' : ''">
            <div class="p-2 mr-3 rounded-lg" :style="{ backgroundColor: content.backgroundColor }">
                <img :src="content.icon" :alt="$t(content.title)">
            </div>
            {{ $t(content.title) }}
        </button>
    </div>

</template>

<script>
export default {
    props: {
        faqArray: Array,
        changeTab: Function,
        activeTitle: String
    }
};
</script>

<style scoped>
button {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;
}

.tabs-container {
    flex-wrap: wrap;
}

.button-tab {
    width: 223px;
    font-size: 14px;
    font-weight: 600;
    text-align: start;
    border: 1px solid transparent;
    background-color: #141417;
    word-wrap: break-word;
}

.faq-active-tab {
    border: 1px solid #3C3A46;
    background-color: #FFFFFF0A;
}

@media only screen and (max-width: 995px) {
    .button-tab {
        margin-bottom: 12px;
    }
}
@media only screen and (max-width: 580px) {
    .button-tab {
        width: 100%;
    }
}
</style>
